import { useState, useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Typography, Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import { useAppStore } from "../appStore";
import axios from "axios";

export default function AddForm({ closeEvent }) {
  const [emp_name, setEmpName] = useState("");
  const [unit, setUnit] = useState("");
  const [emp_code, setEmpCode] = useState("");
  const [emp_classification, setEmpClassification] = useState("");
  const [department, setDepartment] = useState("");
  const [designation, setDesignation] = useState("");
  const [sex, setSex] = useState("");
  const [mobile_no, setMobileNo] = useState("");
  const baseURL = process.env.REACT_APP_API_URL;
  const users = useAppStore((state) => state.users);
  const updateUsers = useAppStore((state) => state.updateUsers);
  const token = useAppStore((state) => state.token);

  const createUser = async (url) => {
    let posturl = baseURL + "employee";

    await axios
      .post(
        posturl,
        {
          unit: unit,
          emp_code: emp_code,
          emp_name: emp_name,
          emp_classification: emp_classification,
          department: department,
          designation: designation,
          sex: sex,
          mobile_no: mobile_no,
        },
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-type": "Application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (res) {
        console.log(res.data);
        getUsers();
        closeEvent();
        Swal.fire("Submitted!", "Your file has been submitted.", "success");
      })
      .catch(function (error) {
        console.log("kcheckpost" + error); //return 429
      });
  };

  const getUsers = async () => {
    let posturl = baseURL + "employee";
    await axios
      .get(posturl, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-type": "Application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        updateUsers(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log("kcheckpost" + err); //return 429
      });
  };

  const handleNameChange = (event) => {
    setEmpName(event.target.value);
  };
  const handleUnitChange = (event) => {
    setUnit(event.target.value);
  };
  const handleEmpCodeChange = (event) => {
    setEmpCode(event.target.value);
  };
  const handleEmpClassificationChange = (event) => {
    setEmpClassification(event.target.value);
  };
  const handleDepartmentChange = (event) => {
    setDepartment(event.target.value);
  };
  const handleDesignationChange = (event) => {
    setDesignation(event.target.value);
  };
  const handleSexChange = (event) => {
    setSex(event.target.value);
  };
  const handleMobileNoChange = (event) => {
    setMobileNo(event.target.value);
  };


  return (
    <>
      <Box sx={{ m: 2 }} />
      <Typography variant="h5" align="center">
        Add Employee
      </Typography>
      <IconButton
        style={{ position: "absolute", top: "0", right: "0" }}
        onClick={closeEvent}
      >
        <CloseIcon />
      </IconButton>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            error={false}
            value={emp_name}
            onChange={handleNameChange}
            label="Name"
            size="small"
            sx={{ marginTop: "10px", minWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={unit}
            onChange={handleUnitChange}
            label="Unit"
            size="small"
            sx={{ marginTop: "10px", minWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={emp_code}
            onChange={handleEmpCodeChange}
            label="Code"
            size="small"
            sx={{ marginTop: "10px", minWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={emp_classification}
            onChange={handleEmpClassificationChange}
            label="Classification"
            size="small"
            sx={{ marginTop: "10px", minWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={department}
            onChange={handleDepartmentChange}
            label="Department"
            size="small"
            sx={{ marginTop: "10px", minWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={designation}
            onChange={handleDesignationChange}
            label="Designnation"
            size="small"
            sx={{ marginTop: "10px", minWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={sex}
            onChange={handleSexChange}
            label="Sex"
            size="small"
            sx={{ marginTop: "10px", minWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={mobile_no}
            onChange={handleMobileNoChange}
            label="Mobile No"
            size="small"
            sx={{ marginTop: "10px", minWidth: "100%" }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" align="center">
            <Button variant="contained" onClick={createUser}>
              Submit
            </Button>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}
