import { Routes, Route, HashRouter } from "react-router-dom";
import User from "./pages/User";
import Whatsapp from "./pages/Whatsapp";
import Employee from "./pages/Employee";
import Login from "./Login";
import { useAppStore } from "./appStore";
import Register from "./pages/Register";
import ForgotPassword from "./pages/ForgotPassword";

export default function App() {
    const utype = useAppStore((state) => state.utype);

    return (
        <>
            <HashRouter>
                <Routes>
                    <Route path="/" exact element={<Login />}></Route>
                    {/* <Route
                        path="/register"
                        exact
                        element={<Register />}
                    ></Route> */}
                    {/* <Route
                        path="/reset-password"
                        exact
                        element={<ForgotPassword />}
                    ></Route> */}
                    {utype === "Admin" && (
                        <>
                            <Route
                                path="/home"
                                exact
                                element={<User />}
                            ></Route>
                            <Route
                                path="/employee"
                                exact
                                element={<Employee />}
                            ></Route>
                            <Route
                                path="/whatsapp"
                                exact
                                element={<Whatsapp />}
                            ></Route>
                            <Route
                                path="/user"
                                exact
                                element={<User />}
                            ></Route>
                        </>
                    )}
                </Routes>
            </HashRouter>
        </>
    );
}
